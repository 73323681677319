import { initializeApp } from "firebase/app";
import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import db from "./firebase";
import { AuthContextProvider, useAuthState, useUserID } from "./firebase";
import { getAuth, signOut } from "firebase/auth";
import logo from './images/logo.png';
import { Line } from 'react-chartjs-2';
import DateTimePicker from 'react-datetime-picker';
import { Form, Button, Card, Alert } from "react-bootstrap"
import './styles.css';
import './App.css';

var arrayHeader = ["timestamp","ecg_uV"];
var arrayHeaderAccel = ["timestamp","accel_x","accel_y","accel_z"];
var arrayMetricHeader = ["timestamp","heartrate","peakToPeakMs","sdann","rmssd","p50NN"];
var arrayData = [];
var arrayMetricData = [];
var arrayDataAccel = [];

export const Home = () => {
  
  var timeData = [];
  var dataECG = [];

  var timeData300 = [];
  var dataECG300 = [];

  var timeDataAccel = [];
  var dataAccelX = [];
  var dataAccelY = [];
  var dataAccelZ = [];

  var labelsData = [];
  var labelsData300 = [];
  var labelsDataAccel = [];
  var labelsMetricData = [];

  var timeMetricData = [];
  var hrData = [];
  var peakToPeakData = [];
  var sdannData = [];
  var rmssdData = [];
  var p50NNData = [];

  var { updatedID } = useUserID();
  var userID = "6GUfarBavscMlw8M6EKS4wEVgx03";

  const [startTime, onChangeStart] = useState(new Date(Date.now() - 1000 * 60 * 5)); //default to last 5 minutes of data
  const [endTime, onChangeEnd] = useState(new Date());
  const [show, setShow] = useState(false);

  const [chartDataRaw, setChartDataRaw] =  React.useState({
   labels: labelsData,
   datasets: [
    {
      data:[],
      label: 'µV - ECG @130Hz',
      backgroundColor: 'rgba(244, 113, 116, 0.5)',
      borderColor: 'rgb(244, 113, 116)',
      fill: false,
      strokeWidth: 1,
      hidden: false
    }
   ] 
  })

  const [chartDataRaw300, setChartDataRaw300] =  React.useState({
   labels: labelsData300,
   datasets: [
    {
      data:[],
      label: 'µV - ECG - 300 samples',
      backgroundColor: 'rgba(150, 55, 87, 0.5)',
      borderColor: 'rgb(150, 55, 87)',
      fill: false,
      strokeWidth: 1,
      hidden: false
    }
   ] 
  })

  const [chartDataRawAccel, setChartDataRawAccel] =  React.useState({
   labels: labelsDataAccel,
   datasets: [
    {
      data:[],
      label: 'accel_x @100Hz',
      backgroundColor: 'rgba(200, 99, 50, 0.5)',
      borderColor: 'rgb(200, 99, 50)',
      fill: false,
      strokeWidth: 1,
      hidden: false
    },
    {
      data:[],
      label: 'accel_y @100Hz',
      backgroundColor: 'rgba(50, 200, 50, 0.5)',
      borderColor: 'rgb(50, 200, 50)',
      fill: false,
      strokeWidth: 1,
      hidden: true
    },
    {
      data:[],
      label: 'accel_z @100Hz',
      backgroundColor: 'rgba(50, 50, 200, 0.5)',
      borderColor: 'rgb(50, 50, 200)',
      fill: false,
      strokeWidth: 1,
      hidden: true
    }
   ] 
  })

  const [chartMetricData, setChartMetricData] = React.useState({
   labels: labelsMetricData,
   datasets: [
    {
      data:[],
      label: 'Heart rate BPM',
      backgroundColor: 'rgba(200, 50, 50, 0.5)',
      borderColor: 'rgb(200, 50, 50)',
      fill: false,
      strokeWidth: 1,
      hidden: false
    },
    {
      data:[],
      label: 'Peak to peak (millis)',
      backgroundColor: 'rgba(50, 200, 200, 0.5)',
      borderColor: 'rgb(50, 200, 200)',
      fill: false,
      strokeWidth: 1,
      hidden: true
    },
    {
      data:[],
      label: 'SDANN',
      backgroundColor: 'rgba(50, 50, 200, 0.5)',
      borderColor: 'rgb(50, 50, 200)',
      fill: false,
      strokeWidth: 1,
      hidden: true
    },
    {
      data:[],
      label: 'RMSSD',
      backgroundColor: 'rgba(200, 50, 200, 0.5)',
      borderColor: 'rgb(200, 50, 200)',
      fill: false,
      strokeWidth: 1,
      hidden: true
    },
    {
      data:[],
      label: 'p50NN',
      backgroundColor: 'rgba(200, 200, 50, 0.5)',
      borderColor: 'rgb(200, 200, 50)',
      fill: false,
      strokeWidth: 1,
      hidden: true
    }
   ]
  })

  const getTimeQueryData = () => {

    clear_data();

    var millisStart = startTime.getTime();
    var millisEnd = endTime.getTime();
    // console.log(millisStart)
    // console.log(millisEnd)

    //only allow less than 24 hour queries
    if ((millisEnd - millisStart) > 24*60*60*1000.0){
      setShow(true);
      AlertDismissible();
      var millisStart = new Date().getTime()
      var millisEnd = new Date().getTime()
    }

    userID = window.userID;
    
    var data = db.collection("userHeartRateData/"+userID+"/ecg_data").where('timestamp', '>=', millisStart).where('timestamp', '<=', millisEnd).orderBy('timestamp', 'desc');
    data.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            timeData.push(...doc.data()["timestamp_array"].reverse());
            dataECG.push(...doc.data()["ecg_array"].reverse());
        });

        let tLen = timeData.length;
        for (let i = 0; i < tLen; i++) {
          if (dataECG.length > 0){
            arrayData.push([timeData[i],dataECG[i]]);
          }
          labelsData.push(millisToMinutesAndSeconds(timeData[i]));
        }
        for (let i = 0; i < 300; i++) {
          dataECG300.push(dataECG[i]);
          labelsData300.push(millisToMinutesAndSeconds(timeData[i]));
        }

        //csv data ordering
        arrayData.reverse();

        //chart data ordering
        labelsData.reverse();
        dataECG.reverse();

        labelsData300.reverse();
        dataECG300.reverse();

        refreshChartRaw();
        refreshChartRaw300();

    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

    getTimeMetricQueryData();
  }

  const getTimeMetricQueryData = () => {

    var millisStart = startTime.getTime()/1000.0;
    var millisEnd = endTime.getTime()/1000.0;
    // console.log(millisStart)
    // console.log(millisEnd)

    //only allow less than 24 hour queries
    if ((millisEnd - millisStart) > 24*60*60){
      setShow(true);
      AlertDismissible();
      var millisStart = new Date().getTime()
      var millisEnd = new Date().getTime()
    }

    userID = window.userID;
    
    var dataMetric = db.collection("userHeartRateData/"+userID+"/heart_rate_data").where('timestamp', '>=', millisStart).where('timestamp', '<=', millisEnd).orderBy('timestamp', 'desc');
    dataMetric.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            timeMetricData.push(doc.data()["timestamp"]*1000.0);
            hrData.push(doc.data()["heartrate"]);
            peakToPeakData.push(doc.data()["peakToPeakMs"]);
            sdannData.push(doc.data()["sdann"]);
            rmssdData.push(doc.data()["rmssd"]);
            p50NNData.push(doc.data()["p50NN"]);
        });

        let mLen = timeMetricData.length;
        for (let i = 0; i < mLen; i++) {
          arrayMetricData.push([timeMetricData[i],hrData[i],peakToPeakData[i],sdannData[i],rmssdData[i],p50NNData[i]]);
          labelsMetricData.push(millisToMinutesAndSeconds(timeMetricData[i]));
        }

        //csv data ordering
        arrayMetricData.reverse();

        //chart data ordering
        labelsMetricData.reverse();
        hrData.reverse();
        peakToPeakData.reverse();
        sdannData.reverse();
        rmssdData.reverse();
        p50NNData.reverse();

        refreshMetricChart();

    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

    getTimeAccelQueryData();
  }

   const getTimeAccelQueryData = () => {

    clear_data();

    var millisStart = startTime.getTime();
    var millisEnd = endTime.getTime();
    // console.log(millisStart)
    // console.log(millisEnd)

    //only allow less than 24 hour queries
    if ((millisEnd - millisStart) > 24*60*60*1000.0){
      setShow(true);
      AlertDismissible();
      var millisStart = new Date().getTime()
      var millisEnd = new Date().getTime()
    }

    userID = window.userID;
    
    var data = db.collection("userHeartRateData/"+userID+"/acceleration_data").where('timestamp', '>=', millisStart).where('timestamp', '<=', millisEnd).orderBy('timestamp', 'desc');
    data.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            timeDataAccel.push(...doc.data()["timestamp_array"].reverse());
            dataAccelX.push(...doc.data()["accelX_array"].reverse());
            dataAccelY.push(...doc.data()["accelY_array"].reverse());
            dataAccelZ.push(...doc.data()["accelZ_array"].reverse());
        });

        let tLen = timeDataAccel.length;
        for (let i = 0; i < tLen; i++) {
          if (dataAccelX.length > 0){
            arrayDataAccel.push([timeDataAccel[i],dataAccelX[i],dataAccelY[i],dataAccelZ[i]]);
          }
          labelsDataAccel.push(millisToMinutesAndSeconds(timeDataAccel[i]));
        }

        //csv data ordering
        arrayDataAccel.reverse();

        //chart data ordering
        labelsDataAccel.reverse();
        dataAccelX.reverse();
        dataAccelY.reverse();
        dataAccelZ.reverse();

        refreshChartRawAccel();

    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });

  }

  const clear_data = () => {
      arrayData = [];
      arrayDataAccel = [];
      arrayMetricData = [];
      timeData = [];
      labelsData = [];
      dataECG = [];
      labelsData300 = [];
      dataECG300 = [];
      labelsDataAccel = [];
      dataAccelX = [];
      dataAccelY = [];
      dataAccelZ = [];
      timeMetricData = [];
      labelsMetricData = [];
      hrData = [];
      peakToPeakData = [];
      sdannData = [];
      rmssdData = [];
      p50NNData = [];
      // console.log('data cleared prior to query');
  }
  
  useEffect(() => { 
    //wait for login to persist
    setTimeout(function () {
        getTimeQueryData()
    }, 1000);
  }, []);

  const export_csv = () => {
    console.log(arrayData)
    let header = arrayHeader.join(",") + '\n';
    let csv = header;
    arrayData.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_raw_data.csv';
    hiddenElement.click();
    console.log("raw - csv saved");
  };

  const export_accel_csv = () => {
    console.log(arrayData)
    let header = arrayHeaderAccel.join(",") + '\n';
    let csv = header;
    arrayDataAccel.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_raw_accel_data.csv';
    hiddenElement.click();
    console.log("raw accel - csv saved");
  };

  const export_metric_csv = () => {
    console.log(arrayData)
    let header = arrayMetricHeader.join(",") + '\n';
    let csv = header;
    arrayMetricData.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_metric_data.csv';
    hiddenElement.click();
    console.log("metric - csv saved");
  };

  const millisToMinutesAndSeconds = (millis) => {
    //time is in GMT
    // console.log(millis);
    var gmtDate = new Date(millis);

    var seconds = gmtDate.getSeconds();
    var minutes = gmtDate.getMinutes();
    var hours = gmtDate.getHours();

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    // console.log(hours + ":" + minutes + ":" + seconds);
    
    return hours + ":" + minutes + ":" + seconds;
  }

  const secondsToMinutesAndSeconds = (totalSeconds) => {
    //time is in GMT
    var gmtDate = new Date(totalSeconds*1000);
    var localDate = gmtDate.toLocaleString();

    var seconds = gmtDate.getSeconds();
    var minutes = gmtDate.getMinutes();
    var hours = gmtDate.getHours();

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  const refreshChartRaw = () => {

        setChartDataRaw({
                      ...chartDataRaw,
                      labels: labelsData,
                      datasets:[
                          {
                              ...chartDataRaw.datasets[0],
                              data: dataECG
                          }
                      ]})

  };

  const refreshChartRaw300 = () => {

        setChartDataRaw300({
                      ...chartDataRaw300,
                      labels: labelsData300,
                      datasets:[
                          {
                              ...chartDataRaw300.datasets[0],
                              data: dataECG300
                          }
                      ]})

  };

  const refreshChartRawAccel = () => {

        setChartDataRawAccel({
                      ...chartDataRawAccel,
                      labels: labelsDataAccel,
                      datasets:[
                          {
                              ...chartDataRawAccel.datasets[0],
                              data: dataAccelX
                          },
                          {
                              ...chartDataRawAccel.datasets[1],
                              data: dataAccelY
                          },
                          {
                              ...chartDataRawAccel.datasets[2],
                              data: dataAccelZ
                          }
                      ]})

  };

  const refreshMetricChart = () => {

        setChartMetricData({
                      ...chartMetricData,
                      labels: labelsMetricData,
                      datasets:[
                          {
                              ...chartMetricData.datasets[0],
                              data: hrData
                          },
                          {
                              ...chartMetricData.datasets[1],
                              data: peakToPeakData
                          },
                          {
                              ...chartMetricData.datasets[2],
                              data: sdannData
                          },
                          {
                              ...chartMetricData.datasets[3],
                              data: rmssdData
                          },
                          {
                              ...chartMetricData.datasets[4],
                              data: p50NNData
                          }
                      ]})
  };


  const AlertDismissible = () => {
      return (
        <>
          <Alert show={show} variant="success">
            <Alert.Heading>Request alert limit!</Alert.Heading>
            <p>
              Presently we only allow a maximum period of 24 hours per request, please change your search to 24 hours or less
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <button onClick={() => setShow(false)} variant="outline-success">
                Close
              </button>
            </div>
          </Alert>
        </>
      );
  };
  
  return (
    <div className="app">
      <div className="App__DataDisplay">
        <AlertDismissible />
        <div className="header">
          <a href="https://www.blueberryx.com/" target="_blank"><img className="logo" src={logo} alt="logo"/></a>
          <div className="header-right">
            <DateTimePicker onChange={onChangeStart} value={startTime} />
            <DateTimePicker onChange={onChangeEnd} value={endTime} />
            <button className="blueButton" onClick={getTimeQueryData}>Get Data</button>
            <button className="blueButton" onClick={export_csv}>Download ECG CSV</button>
            <button className="blueButton" onClick={export_accel_csv}>Download Accel. CSV</button>
            <button className="blueButton" onClick={export_metric_csv}>Download Metric CSV</button>
            <button className="whiteButton" onClick={() => signOut(getAuth())}>Log out</button>
          </div>
        </div>
        <div>
          <Line data={chartDataRaw300} width={window.innerWidth < 760 ? 270 : 400} height={180} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true, 
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartDataRaw} width={window.innerWidth < 760 ? 270 : 400} height={180} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true, 
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartMetricData} width={window.innerWidth < 760 ? 270 : 400} style={180} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true,
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            }, 
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartDataRawAccel} width={window.innerWidth < 760 ? 270 : 400} height={180} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true, 
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
      </div>
      </div>
    </div>
  );
};
