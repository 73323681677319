import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { createContext, useContext, useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyB3bZIT7sW2Sy5wQfRzkZzoLbEOixGDF14",
  authDomain: "blueberry-x-proj.firebaseapp.com",
  databaseURL: "https://blueberry-x-proj.firebaseio.com",
  projectId: "blueberry-x-proj",
  storageBucket: "blueberry-x-proj.appspot.com",
  messagingSenderId: "758407466335",
  appId: "1:758407466335:web:b5cdccae4ea703ace83aea",
  measurementId: "G-493MW79ZXQ"
});

const firebaseConfig = {
  apiKey: "AIzaSyB3bZIT7sW2Sy5wQfRzkZzoLbEOixGDF14",
  authDomain: "blueberry-x-proj.firebaseapp.com",
  databaseURL: "https://blueberry-x-proj.firebaseio.com",
  projectId: "blueberry-x-proj",
  storageBucket: "blueberry-x-proj.appspot.com",
  messagingSenderId: "758407466335",
  appId: "1:758407466335:web:b5cdccae4ea703ace83aea",
  measurementId: "G-493MW79ZXQ"
};

const firebaseAppLocal = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser);
    return () => unsubscribe;
  }, []);

  return <AuthContext.Provider value={user} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  const result = auth != null;

  return { ...auth, isAuthenticated: auth != null };
};

export const useUserID = () => {
    const auth = useContext(AuthContext);
    var userId = "aaa";
    if (auth != null){
      userId = auth.uid;
      window.userID = userId;
      return { ...userId };
    }
};

export default db;