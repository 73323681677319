import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useCallback, useEffect } from "react";
import { useAuthState } from "./firebase";
import { Form, Button, Card, Alert } from "react-bootstrap"
import logo from './images/logo.png';
import './styles.css';
import './App.css';

export const Login = () => {

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const [email, password] = e.target.elements;
    const auth = getAuth();

    //console.log(email.value, password.value);
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
      // console.log("login success");
    } catch (e) {
      alert(e.message);
    }
  }, []);

  return (
    <div>
      <Card>
        <Card.Body className="App-header">
          <img src={logo} className="logo-login" alt="Blueberry logo" />
          <div class="card">
            <form onSubmit={handleSubmit} class="card-form">
              <div class="input">
                <input name="email" type="email" className="input-field" required/>
                <label class="input-label">Email</label>
              </div>
              <div class="input">
                <input name="password" type="password" className="input-field" required/>
                <label class="input-label">Password</label>
              </div>
              <div class="action">
                <button className="action-button" type="submit">Login</button>
              </div>
            </form>
            <div className="card-info">
              <a href="https://blueberryx.com/pages/privacy-policy" target="_blank">Privacy policy</a>
              <p>Blueberry © 2022 - 🫐</p>
            </div>
          </div>
          </Card.Body>
      </Card>
    </div>
  );
};